// 
// badge.scss
//

.badge {
    line-height: 0.95;
}

@each $state in map-keys($theme-colors) { 
    .badge-label {
        &.bg-#{$state} {
            &:before {
                border-right-color: var(--#{$prefix}#{$state});
            }
        }
    }
}

// badge-border
.badge-border {
    border-left: 2px solid;
}

// badge-label
.badge-label {
    margin-left: 8px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-right-color: var(--#{$prefix}primary);
        left: -14px;
        top: 0;
    }
}

//Badge Gradient Style
$gradients: ();
$gradients: map-merge((primary: linear-gradient(135deg, var(--#{$prefix}primary) 0%, var(--#{$prefix}success) 100%),
        secondary: linear-gradient(135deg, var(--#{$prefix}secondary) 0%, var(--#{$prefix}info) 100%),
        success: linear-gradient(135deg, var(--#{$prefix}success) 0%, $warning 100%),
        danger: linear-gradient(135deg, var(--#{$prefix}danger) 0%, var(--#{$prefix}secondary) 100%),
        warning: linear-gradient(135deg, $warning 0%, darken($warning, 15%) 100%),
        info: linear-gradient(135deg, var(--#{$prefix}info) 0%, var(--#{$prefix}success) 100%),
        dark: linear-gradient(135deg, $dark 0%, var(--#{$prefix}primary) 100%)),
    $gradients);

@mixin badge-gradient($parent, $gradient) {
    #{$parent} {
        background: $gradient;
    }
}

@each $gradient,
$value in $gradients {
    @include badge-gradient(".badge-gradient-#{$gradient}", $value);
}