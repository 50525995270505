//
// alerts.scss
//

// alert-label-icon

.alert-label-icon {
    position: relative;
    padding-left: 60px;
    border: 0;

    .label-icon {
        position: absolute;
        width: 45px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.15);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // label arrow
    &.label-arrow {
        overflow: hidden;

        .label-icon {
            &:after {
                content: "";
                position: absolute;
                border: 6px solid transparent;
                border-left-color: var(--#{$prefix}primary);
                right: -12px;
            }
        }
    }
}


// Alert border left example

.alert-border-left {
    border-left: 3px solid;
}

// alert top border example

.alert-top-border {
    background-color: var(--#{$prefix}secondary-bg);
    border-color: var(--#{$prefix}border-color);
    border-top: 2px solid;
    color: var(--#{$prefix}body-color);
}

//alert additional example

.alert-additional {
    padding: 0;

    .alert-body {
        padding: $alert-padding-y $alert-padding-x;
    }

    .alert-content {
        padding: $alert-padding-y $alert-padding-x;
        border-bottom-left-radius: $alert-border-radius;
        border-bottom-right-radius: $alert-border-radius;
        margin: 0 (-$alert-border-width) (-$alert-border-width) (-$alert-border-width);
    }
}

// rounded label example

.rounded-label {
    .label-icon {
        width: 45px;
        height: 26px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 0 30px 30px 0;
    }
}

// Alert example loop
@each $state in map-keys($theme-colors) {

    // alert border left
    .alert-border-left {
        &.alert-#{$state} {
            border-left-color: var(--#{$prefix}#{$state});
        }
    }

    // alert top border
    .alert-top-border {
        &.alert-#{$state} {
            border-top-color: var(--#{$prefix}#{$state});
        }
    }

    // rounded label
    .rounded-label {
        &.alert-#{$state} {
            .label-icon {
                background-color: var(--#{$prefix}#{$state});
            }
        }
    }

    // label arrow
    .label-arrow {
        &.alert-#{$state} {
            .label-icon {
                background-color: var(--#{$prefix}#{$state});

                &:after {
                    border-left-color: var(--#{$prefix}#{$state}) !important;
                }
            }
        }
    }

    .alert {
        &.alert-#{$state} {
            .btn-close::after {
                color: var(--#{$prefix}#{$state});
            }
        }
    }

    //alert additional example
    .alert-additional {
        &.alert-#{$state} {
            .alert-content {
                background-color: var(--#{$prefix}#{$state});
            }
        }
    }

}


@each $color, $value in $theme-colors {
    .alert-#{$color} {
        &:is(.rounded-label, .label-arrow, .alert-additional) :is(.label-icon, .alert-content) {
            color: color-contrast($value);
        }
    }
}

.alert-dismissible {
    .btn-close {
        background: transparent !important;

        &::after {
            background: transparent !important;
            content: "\F0156" !important;
            font-size: 18px;
            line-height: 15px;
            font-family: "Material Design Icons" !important;
        }
    }
}